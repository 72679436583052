const appconstant = {
  oldPasswordRequired: "Please enter old password.",
  newPasswordRequired: "Please enter new password.",
  confirmNewPasswordRequired: "Please enter confirm new password.",
  confirmNewPasswordMustBeSame: "Confirm new password must match with new password.",
  confirmPasswordRequired: "Please enter confirm password.",
  confirmPasswordMustBeSame: "Confirm password must match with password.",
  passwordValidation: "Password must include 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.",
  minPasswordValidation: "Password must have at least 4 characters.",
  passwordChanged: "Password has been changed successfully.",
  forgotPasswordLinkSent: "Reset password link has been sent to your registered email address.",

  emailRequired: "Please enter email address.",
  invalidEmail: "Please enter valid email address.",
  passwordRequired: "Please enter password.",
  messageStatus500: "Something went wrong.",
  offlineMessage: "Your internet connection appears to be offline. Please try again.",
  offline: "Offline",
  resetPassword: "Reset Password",
  linkExpireMessage: "Link has been expired.",
  changepassword: 'Change Password',
  Oldpassword: 'Old Password',
  NewPassword: 'New Password',
  ConfirmNewPassword: 'Confirm New Password',
  contactus: 'Contact us',
  home: 'Dashboard',
  EmailAddress: 'Email Address',
  eventManagement: 'Event Management',
  UserManagement: 'User Management',
  Password: 'Password',
  ConfrmPassword: 'Confrm Password',
  email: 'Email',
  contactNo: 'Contact No',
  gender: 'Gender',
  age: 'Age',
  name: 'Name',
  view: 'View',
  edit: 'Edit',
  block: 'Block',
  userReportManagement: 'User Report Management',
  UserManagemen: "User Managemen",
  AddFavorite: "Add Favorite",
  add: "Add",
  Settings: "Settings",
  Disable: "Disable",
  favoriteName: "Favorite Name",
  InventoryManagement: "Inventory Management",
  Export: "Export",
  Delete: "Delete",
  category: "Category",
  changeLanguage: "Change Language",
  adsManagement: "Ads Management",
  Enable: "Enable",






  //
  buttonupate: 'Update',
  reduxConstant: {

    // Base Url
    // SERVER_URL: "http://192.168.3.177:9044/api/v1", //local
    // IMAGE_URL: "http://192.168.3.177:9044",

    // SERVER_URL: "http://1.6.98.141:9044/api/v1", //dell
    // IMAGE_URL: "http://1.6.98.141:9044",

    SERVER_URL: "https://api.super-note.ai/api/v1", //live
    IMAGE_URL: "https://api.super-note.ai",

    // //SERVER_URL: "http://1.6.98.141:9044/api/v1", // del
    // SERVER_URL_SOCKET: "http://1.6.98.141:3017", //del
    // SERVER_URL: "https://api.chessarena.app/api/v1", // client
    // SERVER_URL_SOCKET: "https://api.chessarena.app/", //client


    //LOGIN//

    LOGIN_LOAD: "LOGIN_LOAD",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAIL: "LOGIN_FAIL",

    //FORGOT PASSWORD

    FORGOTPASSWORD_LOAD: "FORGOTPASSWORD_LOAD",
    FORGOTPASSWORD_SUCCESS: "FORGOTPASSWORD_SUCCESS",
    FORGOTPASSWORD_FAIL: "FORGOTPASSWORD_FAIL",

    //RESET PASSWORD

    RESET_PASSWORD_LOAD: "RESET_PASSWORD_LOAD",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

    //check reset password link

    CHECK_LINK_LOAD: "CHECK_LINK_LOAD",
    CHECK_LINK_SUCCESS: "CHECK_LINK_SUCCESS",
    CHECK_LINK_FAIL: "CHECK_LINK_FAIL",

    //check reset password link

    CHANGE_PASSWORD_LOAD: "CHANGE_PASSWORD_LOAD",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

    //Logout

    LOGOUT_LOAD: "LOGOUT_LOAD",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAIL: "LOGOUT_FAIL",

    //Inventory
    INVENTORY_LOAD: "INVENTORY_LOAD",
    INVENTORY_SUCCESS: "INVENTORY_SUCCESS",
    INVENTORY_FAIL: "INVENTORY_FAIL",



    //FAVOURITES

    ADD_FAVOURITE_LOAD: "ADD_FAVOURITE_LOAD",
    ADD_FAVOURITE_SUCCESS: "ADD_FAVOURITE_SUCCESS",
    ADD_FAVOURITE_FAIL: "ADD_FAVOURITE_FAIL",


    CATEGORY_LIST_LOAD: "CATEGORY_LIST_LOAD",
    CATEGORY_LIST_SUCCESS: "CATEGORY_LIST_SUCCESS",
    CATEGORY_LIST_FAIL: "CATEGORY_LIST_FAIL",


    CATEGORY_DISABLE_LOAD: "CATEGORY_DISABLE_LOAD",
    CATEGORY_DISABLE_SUCCESS: "CATEGORY_DISABLE_SUCCESS",
    CATEGORY_DISABLE_FAIL: "CATEGORY_DISABLE_FAIL",

    CATEGORY_DELETE_LOAD: "CATEGORY_DELETE_LOAD",
    CATEGORY_DELETE_SUCCESS: "CATEGORY_DELETE_SUCCESS",
    CATEGORY_DELETE_FAIL: "CATEGORY_DELETE_FAIL",

    CATEGORY_EDIT_LOAD: "CATEGORY_EDIT_LOAD",
    CATEGORY_EDIT_SUCCESS: "CATEGORY_EDIT_SUCCESS",
    CATEGORY_EDIT_FAIL: "CATEGORY_EDIT_FAIL",


    //ADS

    ADS_LIST_LOAD: "ADS_LIST_LOAD",
    ADS_LIST_SUCCESS: "ADS_LIST_SUCCESS",
    ADS_LIST_FAIL: "ADS_LIST_FAIL",


    ADS_DELETE_LOAD: "ADS_DELETE_LOAD",
    ADS_DELETE_SUCCESS: "ADS_DELETE_SUCCESS",
    ADS_DELETE_FAIL: "ADS_DELETE_FAIL",


    ADS_ADD_LOAD: "ADS_ADD_LOAD",
    ADS_ADD_SUCCESS: "ADS_ADD_SUCCESS",
    ADS_ADD_FAIL: "ADS_ADD_FAIL",


    ADS_EDIT_LOAD: "ADS_EDIT_LOAD",
    ADS_EDIT_SUCCESS: "ADS_EDIT_SUCCESS",
    ADS_EDIT_FAIL: "ADS_EDIT_FAIL",



    //ERROR
    ERROR: "ERROR",
    FAIL: "FAIL",
    RESET: "RESET",
    RESET_GAMES: "RESET_GAMES",
    RESET_STORE: "RESET_STORE",
    ON_LOAD: "ON_LOAD",
    ON_SUCCESS: "ON_SUCCESS",
    ON_FAIL: "ON_FAIL",
    REMOVE_LOADER: "REMOVE_LOADER",

  },

}
export default appconstant