import React, { useState, useEffect } from "react";
import { Layout, Menu, Pagination } from "antd";
import images from "../../themes/appImage";
import { DatePicker, Space } from "antd";
import * as Yup from 'yup'

import { BrowserRouter as Router, Link } from "react-router-dom";
import HeaderAdmin from "../../layout/header";

import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faNewspaper,
  faBell,
  faShoppingCart,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";
import MobileSidebar from "../../layout/mobilesidebar";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import Textfiled from "../../common";
import { useFormik } from 'formik'
import { addFavoriteCategory, categoryList, deleteCategory, disableCategory, editCategory } from '../../Store/Action/favourite'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { ColorPicker } from 'antd';

const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;

const AddFavorite = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [favouriteName, setFavouriteName] = useState('');
  const [favouriteNotes, setFavouriteNotes] = useState('');
  const [category, setCategory] = useState('');
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')

  const list = useSelector((state) => state.FavouriteReducer.favouriteList)

  const pagination = useSelector((state) => state.FavouriteReducer.favouriteListPagination)


  useEffect(() => {
    if (pagination?.currentPage === 1) {
      setOffset(0)
      setLimit(10)
    }
  }, [list])


  useEffect(() => {
    console.log('modal1Open', modal1Open, modal2Open)
    if (modal1Open || modal2Open) {
      document.body.style.overflow = "hidden !important"
    }
    else {
      document.body.style.overflow = "scroll !important"
    }

  }, [modal1Open, modal2Open])



  useEffect(() => {
    dispatch(categoryList({ offset, limit }))
  }, [])


  //validator

  const validationSchema = Yup.object().shape({
    categoryName: Yup.string()
      .required("Please enter favorite name.")
      .min(3, 'Favorite name must contain at least 3 characters.')
      .max(30, 'Favorite name can contain at most 30 characters.')
      .matches(
        /^[a-zA-Z0-9- ]+$/,
        "Please enter valid favorite name."
      )
    ,
    categoryNameHb: Yup.string()
      .required("Please enter favorite name.")
      .min(3, 'Favorite name must contain at least 3 characters.')
      .max(30, 'Favorite name can contain at most 30 characters.')
    // .matches(
    //   /^[a-zA-Z0-9- ]+$/,
    //   "Please enter valid favorite name."
    // )
    ,

    categoryNameSp: Yup.string()
      .required("Please enter favorite name.")
      .min(3, 'Favorite name must contain at least 3 characters.')
      .max(30, 'Favorite name can contain at most 30 characters.')
    // .matches(
    //   /^[a-zA-Z0-9- ]+$/,
    //   "Please enter valid favorite name."
    // )
    ,

    categoryNameRussian: Yup.string()
      .required("Please enter favorite name.")
      .min(3, 'Favorite name must contain at least 3 characters.')
      .max(30, 'Favorite name can contain at most 30 characters.')
    // .matches(
    //   /^[a-zA-Z0-9- ]+$/,
    //   "Please enter valid favorite name."
    // )
    ,

    categoryNameArabic: Yup.string()
      .required("Please enter favorite name.")
      .min(3, 'Favorite name must contain at least 3 characters.')
      .max(30, 'Favorite name can contain at most 30 characters.')
    // .matches(
    //   /^[a-zA-Z0-9- ]+$/,
    //   "Please enter valid favorite name."
    // )
    ,

    note: Yup.string()
      .required("Please enter note.")
      .min(3, 'Note must contain at least 3 characters.')
      .max(300, 'Note can contain at most 300 characters.'),

    favoriteColor: Yup.string()
      .required("Please select favorite color.")
  });

  const [addinitialValues, setaddInitialValues] = useState({
    categoryName: "",
    note: "",
    favoriteColor: ""
  })

  const formik = useFormik({
    initialValues: {
      categoryName: "",
      categoryNameHb: "",
      categoryNameSp: "",
      categoryNameRussian: "",
      categoryNameArabic: "",
      note: "",
      favoriteColor: ""
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(addFavoriteCategory(values, navigate))
      setSearch('')
      setModal1Open(false)

    },
    validationSchema
  })


  const [initialValues, setInitialValues] = useState({
    category: "",
    categoryName: "",
    categoryNameHb: "",
    categoryNameSp: "",
    categoryNameRussian: "",
    categoryNameArabic: "",
    note: "",
    favoriteColor: ""
  })

  const editformik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(editCategory(values))
      setSearch('')
      setModal2Open(false)
    },
    validationSchema
  })




  const disableModel = (record) => {
    const key = record.disable ? "enable" : "disable"
    Modal.confirm({
      icon: <img className="moale-image" src={images.alert} />,
      content: (
        <div className="content-model">
          <p> Are you sure,</p> <p>{`You want to ${key} this favorite?`} </p>
        </div>
      ),
      okText: "Yes",
      centered: true,
      cancelText: "No",
      onOk() {
        dispatch(disableCategory({ category: record.id }))
      },
      onCancel() { },
      wrapClassName: "new-button",
    });
  };

  const deleteModel = (record) => {
    Modal.confirm({
      title: "Delete",
      content: `Are you sure, you want to delete this favorite?`,
      okText: "Yes",
      centered: true,
      cancelText: "No",
      onOk() {
        console.log('deleteCategory')
        dispatch(deleteCategory({ category: record.id, offset, limit }))
      },
      onCancel() { },
      className: "new-button",
    });
  };

  // const dataSource = [
  //   {
  //     key: "1",
  //     name: "Super",
  //     notes: "Lorem ipsum is a dummy text...",
  //   },
  //   {
  //     key: "2",
  //     name: "Super",
  //     notes: "Lorem ipsum is a dummy text...",
  //   },
  //   {
  //     key: "3",
  //     name: "Super",
  //     notes: "Lorem ipsum is a dummy text...",
  //   },
  //   {
  //     key: "4",
  //     name: "Super",
  //     notes: "Lorem ipsum is a dummy text...",
  //   },
  //   {
  //     key: "5",
  //     name: "Super",
  //     notes: "Lorem ipsum is a dummy text...",
  //   },
  // ];

  const dataSource = list.length ? list.map(e => {
    return {
      id: e._id, name: e.categoryName,
      hbName: e.nameHe, ruName: e.nameRu, arName: e.nameAr, spName: e.nameEs,
      notes: e.note, disable: e.disable, favoriteColor: e.favoriteColor
    }
  }) : []

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => offset + index + 1,
    },

    {
      title: "Favorite Name",
      dataIndex: "name",
      key: "name",
      sorter: true
    },

    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      sorter: true,
    },

    {
      title: "Actions",
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item, index) => {
        return (
          <div>
            <button onClick={() => {
              console.log("item", item);
              // setCategory(item?.id)
              // setFavouriteName(item?.name)
              // setFavouriteNotes(item?.notes)

              editformik.resetForm()
              // editformik.setFieldValue("category", item?.id)
              // editformik.setFieldValue("categoryName", item?.name)
              // editformik.setFieldValue("note", item?.notes)
              setInitialValues({
                categoryName: item.name,
                categoryNameHb: item?.hbName,
                categoryNameSp: item?.spName,
                categoryNameRussian: item?.ruName,
                categoryNameArabic: item?.arName,
                note: item.notes,
                category: item.id,
                favoriteColor: item.favoriteColor
              })
              setFavouriteNotes(item.notes)
              setModal2Open(true);
            }} type="submit">{appconstant.edit}</button>

            {/* <button
              className="disable-model"
              type="submit"
              onClick={() => disableModel(item)}
            >

              {item?.disable ? appconstant.Enable : appconstant.Disable}
            </button> */}
            <button
              className="disable-model"
              type="submit"
              onClick={() => deleteModel(item)}
            >

              {appconstant.Delete}
            </button>
          </div>
        );
      },
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalVisibleBlock, setIsModalVisibleBlock] = useState(false);
  const showModalBlock = () => {
    setIsModalVisibleBlock(true);
  };

  const handleOkBlock = () => {
    setIsModalVisibleBlock(false);
  };

  const handleCancelBlock = () => {
    setIsModalVisibleBlock(false);
  };

  const [isVisible, setVisible] = useState(false);
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = 'Super Note';
    return () => Modal.destroyAll()
  }, []);


  console.log('editValues', editformik.errors, editformik.values)
  return (
    <div>
      <BreadcrumbFn
        path={["usermanagement"]}
        pathName={[<img src={images.home} />, appconstant.AddFavorite]}
      />
      {/* Add model */}

      <form onSubmit={formik.handleSubmit} autocomplete="off">
        <div className="view-offer-modal ">
          <Modal
            title="Add Favorite"
            className="cross-model offer-person-modal "
            centered
            visible={modal1Open}
            destroyOnClose={true}
            onCancel={() => {
              setModal1Open(false)
            }}
            footer={[
              <button className="can-btn" onClick={() => setModal1Open(false)}>
                Cancel
              </button>,
              <button className="add-fav" type='submit' onClick={formik.submitForm} >
                Add Favorite
              </button>,
            ]}
          >
            <div className="modal-offer ">
              <div className="input-offer-modal">
                <Textfiled
                  // label={appconstant.title}
                  autocomplete={false}
                  maxLength={30}
                  placeholder={appconstant.favoriteName}
                  name="categoryName"
                  value={(formik.values.categoryName).trimStart()}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.errors.categoryName ? <><div className="select">{formik.errors.categoryName}</div></> : null}

              {/* //hb */}
              <div className="input-offer-modal">
                <Textfiled
                  // label={appconstant.title}
                  autocomplete={false}
                  maxLength={30}
                  placeholder={'שם אהוב'}
                  name="categoryNameHb"
                  value={(formik.values.categoryNameHb).trimStart()}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.errors.categoryNameHb ? <><div className="select">{formik.errors.categoryNameHb}</div></> : null}

              {/* //spanish */}
              <div className="input-offer-modal">
                <Textfiled
                  // label={appconstant.title}
                  autocomplete={false}
                  maxLength={30}
                  placeholder={'Nombre favorito'}
                  name="categoryNameSp"
                  value={(formik.values.categoryNameSp).trimStart()}
                  onChange={formik.handleChange}
                />
              </div>

              {formik.errors.categoryNameSp ? <><div className="select">{formik.errors.categoryNameSp}</div></> : null}

              {/* //arabic */}
              <div className="input-offer-modal">
                <Textfiled
                  // label={appconstant.title}
                  autocomplete={false}
                  maxLength={30}
                  placeholder={'الاسم المفضل'}
                  name="categoryNameArabic"
                  value={(formik.values.categoryNameArabic).trimStart()}
                  onChange={formik.handleChange}
                />
              </div>

              {formik.errors.categoryNameSp ? <><div className="select">{formik.errors.categoryNameSp}</div></> : null}

              <div className="input-offer-modal">
                <Textfiled
                  // label={appconstant.title}
                  autocomplete={false}
                  maxLength={30}
                  placeholder={'Любимое имя'}
                  name="categoryNameRussian"
                  value={(formik.values.categoryNameRussian).trimStart()}
                  onChange={formik.handleChange}
                />
              </div>

              {formik.errors.categoryNameRussian ? <><div className="select">{formik.errors.categoryNameRussian}</div></> : null}

              <div className="input-offer-modal">
                {/* <label>Description</label> */}
                <textarea
                  id="w3review"
                  name="note"
                  value={(formik.values.note).trimStart()}
                  maxLength={300}
                  rows="4"
                  cols="50"
                  placeholder="Add Note"
                  onChange={formik.handleChange}
                  onInput={(e) => {
                    setFavouriteNotes((e.target.value).trimStart())
                  }}
                />
                {formik.errors.note ? <><div className="login-validation">{formik.errors.note}</div></> : null}
                <p className="text-veli">{`${favouriteNotes.length}/300`}</p>
              </div>
              <label><b>Select Color</b></label>
              <div className="input-offer-modal">
                <input
                  name="favoriteColor"
                  type="color"
                  value={formik.values.favoriteColor}
                  defaultValue={formik.values.favoriteColor}
                  onChange={formik.handleChange}
                  style={{ cursor: "pointer", borderRadius: '10px' }}
                ></input>
              </div>
              {formik.errors.favoriteColor ? <><div className="login-validation">{formik.errors.favoriteColor}</div></> : null}
            </div>
          </Modal>
        </div>
      </form>
      {/* end */}
      <div
        className="site-layout-background"
        style={{ minHeight: 360 }}
      >
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 ">
            <h6 class="text-white text-capitalize ps-3">
              {appconstant.AddFavorite}
            </h6>
          </div>

          <div className="system-line">
            <button
              onClick={() => {
                formik.values.categoryName = ""
                formik.values.categoryNameHb = ""
                formik.values.categoryNameSp = ""
                formik.values.categoryNameRussian = ""
                formik.values.categoryNameArabic = ""
                formik.values.note = ""
                formik.values.favoriteColor = ""
                formik.setErrors({})
                setFavouriteNotes('')
                // formik.setFieldValue('categoryName', "")
                // formik.setFieldValue('note', "")
                // setaddInitialValues({
                //   categoryName: "",
                //   note: ""
                // })
                setModal1Open(true);
              }}

              className="button-list"
            >
              {appconstant.add}
            </button>

            <input
              type="text"
              className="iocn-search  search-bar"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                const value = (e.target.value).trimStart()
                setSearch(value)
                dispatch(categoryList({ search: value }))
              }}
            />
          </div>
          <div className="tableContainer">
            <Table
              dataSource={dataSource}
              columns={columns}
              showSorterTooltip={false}
              onChange={(aaa, aa, sort) => {
                const { field, order } = sort
                let sortData = null
                if (field === "notes") sortData = 'note'
                else if (field === "Favorite Name") sortData = 'categoryName'
                let orderStatus = null
                if (order === "ascend") orderStatus = 1
                else if (order === "descend") orderStatus = -1
                dispatch(categoryList({ sort: sortData, order: orderStatus, offset: search ? 0 : offset, limit: search ? 10 : limit, search }))

              }}

            />
          </div>
          <div className="bott-pagination">
            <Pagination current={pagination?.currentPage} defaultCurrent={1} total={pagination?.totalCount}
              showSizeChanger={false}
              onChange={(pageNumber) => {
                const pageSize = 10
                setOffset(((pageNumber - 1) * pageSize))
                setLimit(pageSize)
                dispatch(categoryList({ offset: ((pageNumber - 1) * pageSize), limit: pageSize }))
              }}
            />
          </div>

        </div>
      </div>

      {/* Edit model */}
      <form onSubmit={editformik.handleSubmit} autocomplete={"off"}>
        <div className="view-offer-modal ">
          <Modal
            title="Edit Favorite"
            className="cross-model offer-person-modal "
            centered
            visible={modal2Open}
            onCancel={() => setModal2Open(false)}
            footer={[
              <button className="can-btn" onClick={() => setModal2Open(false)}>
                Cancel
              </button>,
              <button className="add-fav modal-add" type='submit' onClick={editformik.submitForm}>
                Update
              </button>,
            ]}
          >
            <div className="modal-offer ">
              <div className="input-offer-modal">
                <Textfiled
                  placeholder={appconstant.favoriteName}
                  autocomplete={"off"}
                  name="categoryName"
                  value={editformik.values.categoryName}
                  maxLength={30}
                  onChange={
                    (e) => {
                      const value = (e.target.value).trimStart()
                      editformik.setFieldValue("categoryName", value)
                      // editformik.setFieldValue("category", category)
                      //setFavouriteName(value);
                      //editformik.handleChange
                    }
                  }
                />
              </div>
              {editformik.errors.categoryName ? <><div className="select">{editformik.errors.categoryName}</div></> : null}

              {/* //hb */}
              <div className="input-offer-modal">
                <Textfiled
                  // label={appconstant.title}
                  autocomplete={false}
                  maxLength={30}
                  placeholder={'שם אהוב'}
                  name="categoryNameHb"
                  value={(editformik.values.categoryNameHb)}
                  onChange={
                    (e) => {
                      const value = (e.target.value).trimStart()
                      editformik.setFieldValue("categoryNameHb", value)
                    }
                  }
                />
              </div>
              {editformik.errors.categoryNameHb ? <><div className="select">{editformik.errors.categoryNameHb}</div></> : null}

              {/* //spanish */}
              <div className="input-offer-modal">
                <Textfiled
                  // label={appconstant.title}
                  autocomplete={false}
                  maxLength={30}
                  placeholder={'Nombre favorito'}
                  name="categoryNameSp"
                  value={(editformik.values.categoryNameSp)}
                  onChange={
                    (e) => {
                      const value = (e.target.value).trimStart()
                      editformik.setFieldValue("categoryNameSp", value)
                    }
                  }
                />
              </div>

              {editformik.errors.categoryNameSp ? <><div className="select">{editformik.errors.categoryNameSp}</div></> : null}

              {/* //arabic */}
              <div className="input-offer-modal">
                <Textfiled
                  // label={appconstant.title}
                  autocomplete={false}
                  maxLength={30}
                  placeholder={'الاسم المفضل'}
                  name="categoryNameArabic"
                  value={(editformik.values.categoryNameArabic)}
                  onChange={
                    (e) => {
                      const value = (e.target.value).trimStart()
                      editformik.setFieldValue("categoryNameArabic", value)
                    }
                  }
                />
              </div>

              {editformik.errors.categoryNameSp ? <><div className="select">{editformik.errors.categoryNameSp}</div></> : null}

              <div className="input-offer-modal">
                <Textfiled
                  // label={appconstant.title}
                  autocomplete={false}
                  maxLength={30}
                  placeholder={'Любимое имя'}
                  name="categoryNameRussian"
                  value={(editformik.values.categoryNameRussian)}
                  onChange={
                    (e) => {
                      const value = (e.target.value).trimStart()
                      editformik.setFieldValue("categoryNameRussian", value)
                    }
                  }
                />
              </div>

              {editformik.errors.categoryNameRussian ? <><div className="select">{editformik.errors.categoryNameRussian}</div></> : null}


              <div className="input-offer-modal">
                {/* <label>Description</label> */}
                <textarea
                  id="w3review"
                  name="note"
                  rows="4"
                  cols="50"
                  placeholder="Add Note"
                  value={editformik.values.note}
                  maxLength={300}
                  onChange={
                    (e) => {
                      const value = (e.target.value).trimStart()
                      editformik.setFieldValue("note", value)
                      setFavouriteNotes(value);
                      //editformik.handleChange
                    }
                    //editformik.handleChange}
                  }
                />
                {editformik.errors.note ? <><div className="login-validation">{editformik.errors.note}</div></> : null}
                <p className="text-veli">{`${favouriteNotes.length} / 300`}</p>
              </div>

              <label><b>Select Color</b></label>
              <div className="input-offer-modal">
                <input
                  name="favoriteColor"
                  type="color"
                  value={editformik.values.favoriteColor}
                  // defaultValue={"#000"}
                  onChange={editformik.handleChange}
                  style={{ cursor: "pointer", borderRadius: '10px' }}
                ></input>
              </div>
              {editformik.errors.favoriteColor ? <><div className="login-validation">{editformik.errors.favoriteColor}</div></> : null}

            </div>
          </Modal>
        </div>
      </form>
      {/* end */}
    </div>
  );
};
export default AddFavorite;
